export const getSystemType = async () => {
  return [
    { label: 'OA', value: 'OA' },
    { label: 'ERPA', value: 'ERPA' },
    { label: 'ERPC', value: 'ERPC' },
    { label: '金蝶', value: 'JD' },
    { label: '供应链', value: 'GYL' },
    { label: '中台系统', value: 'ZT' },
    { label: '其他', value: 'QT' },
  ]
}
